.search_page {
    /* min-height: calc(100vh - 110px); */
    background-image: url('../../assets/images/search_bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.search_page>div:nth-child(1) {
    background: rgba(3, 149, 197, 0.9);
}

.search_page>div:nth-child(2) {
    background: rgba(255, 255, 255, 0.9);
}

.titles_container {
    margin-top: 180px;
    padding-bottom: 30px;
}

.title {
    width: 100%;
    font-size: 46px;
    line-height: 1.1em;
    color: var(--pure-white);
    margin-bottom: 48px;
    white-space: pre-wrap;
    text-align: center;
}

.sub_title {
    padding-right: 100px;
    color: var(--pure-white);
}

.titles_container>p:nth-of-type(1) {
    margin-bottom: 24px;
}

.readmore {
    display: flex;
    margin-bottom: 24px;
}

.readmore_container {
    display: flex;
    align-items: center;
}

.readmore_label {
    color: var(--pure-white);
    margin: 0;
    padding-right: 8px;
    cursor: pointer;
}

.readmore_icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.showReadMore {
    color: var(--pure-white);
}

.hideReadMore {
    display: none;
    /* visibility: hidden; */
    /* padding-top: 10px; */
    color: var(--pure-white);
}

.search_form_container {
    /* margin: 64px 0 94px -70px; */
    margin: 48px 0 48px -70px;
    width: 100%;
}

.internal_review_and_application_no {
    margin: 0;
    text-align: right;
}

.hide_in_mobile {
    display: unset;
}

@media screen and (min-width: 992px) {
    .search_form_container {
        max-width: 450px;
        flex: 100%;
    }
}

@media screen and (max-width: 1399.98px) {
    .title {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 1300px) {
    .title {
        font-size: 32px;
    }
}

@media screen and (max-width: 991.98px) {
    .search_form_container {
        margin: -160px auto 56px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .titles_container {
        margin: 32px 40px 200px;
        padding: 0;
    }
    .sub_title {
        padding-right: 0;
    }
}

@media screen and (max-width: 767.98px) {
    .titles_container {
        margin-left: 24px;
        margin-right: 24px;
    }
    .search_form_container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media screen and (max-width: 640.98px) {
    .hide_in_mobile {
        display: none;
    }
}

@media screen and (max-width: 575.98px) {
    .titles_container {
        margin-left: 15px;
        margin-right: 15px;
    }
    .search_form_container {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 32px;
    }
    .title {
        margin-bottom: 24px;
    }
    .readmore,
    .titles_container>p:nth-of-type(1) {
        margin-bottom: 16px;
    }
}
/* Custom Radio Box */
.custom_radio_box {    
    display: flex;
    align-items: center;
    padding-left: 25px;
    position: relative;
}
.custom_radio_box input {
    opacity: 0;
    z-index: 9999;
}
.custom_radio_box input,
.custom_radio {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
}
.custom_radio_box .custom_radio {
    /* background: transparent; */
    border: 1px solid var(--grey-goose);
    border-radius: 3px;
    border-radius: 50%;
}
.custom_radio_box input:checked ~ .custom_radio {
    border: 6px solid var(--cerulean);
}
.custom_radio_box input:checked ~ span span {
    background-color: #f00;
}
.custom_radio_box label {
    margin-bottom: 0;
    cursor: pointer;
}

@media screen and (max-width: 575.98px) {    
    .custom_radio_box input,
    .custom_radio {
        width: 18px;
        height: 18px;
    }
    .custom_radio_box input:checked ~ .custom_radio {
        border: 5px solid var(--cerulean);
    }
    .custom_radio_box {  
        padding-left: 24px !important;
    }
}
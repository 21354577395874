.schedule_meetup {
    padding-top: 32px;
    padding-bottom: 56px;
    background-color: var(--white-lilac);
}

.schedule_meetup .title {
    margin-top: 16px;
    margin-bottom: 16px;
}

/* @media (max-width: 1399.98px) {
    .schedule_meetup .steps_container {
        max-width: 85%;
        flex: 85%;
    }
} */
@media (min-width: 1000px) {
    .schedule_meetup .steps_container {
        max-width: 1000px;
        flex: 100%;
    }
}
@media (max-width: 999.98px) {
    .schedule_meetup .steps_container {        
        max-width: 100%;
        flex: 100%;
    }
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .schedule_meetup {
        padding-top: 16px;
    }    
    .schedule_meetup .title {
        margin-top: 8px;
        margin-bottom: 24px;
    }
    .schedule_meetup {
        padding-bottom: 32px;
    }
}
.branch-mi {
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 6px;
    background-color: var(--white-lilac);
    display: flex;
}

.selected {
    background-color: var(--alice-blue);
}

.radio-button-container {
    width: 32px;
    position: relative;
}

.radio-button {
    position: static;
}

.info-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.branch-name, .branch-postcode, .branch-address, .branch-contact {
    font-weight: 600;
}

.branch-name {
    width: 100%;
    margin-bottom: 4px;
}

.branch-postcode {
    margin-right: 8px;
}

.branch-address {
    margin-right: 16px;
}

@media screen and (max-width: 768px) {
    .radio-button {
        position: relative;
        margin-top: 12px;
    }
}

@media screen and (max-width: 575.98px){
    .branch-name, .branch-postcode, .branch-address, .branch-contact {
        font-size: 12px;
    }
}
.footer_bar {
    width: 100%;
    height: 50px; 
    margin: 0;
    padding-top: 10;
    padding-bottom: 10; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    background-color: var(--pure-white);
}

.footer_bar .footer_label {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ai_direct_question_row>div:first-child {
    margin-bottom: 0;
}
.ai_direct_question_row>div {
    margin-bottom: 8px;
}
.ai_direct_question_row>div:last-child {
    margin-bottom: 32px;
}
.ai_direct_question_row .question_container {
    margin-bottom: 24px;
}
.ai_direct_question_row .question_container h5 {
    display: inline;
    vertical-align: bottom;
    margin-right: 8px;
}

@media screen and (max-width: 991.98px) {
    .ai_direct_question_row {
        margin-bottom: 16px;
    }      
    .ai_direct_question_row>div:last-child {
        margin-bottom: 16px;
    }
    .buttons_container {
        margin-top: 32px;
    }
}
@media screen and (max-width: 767.98px) {
    .ai_direct_question_row {
        margin-bottom: 8px;
    }  
    .buttons_container {
        margin-top: 16px;
    }
}
@media screen and (max-width: 575.98px) {
    .ai_direct_question_row>div:last-child {
        margin-bottom: 8px !important;
    } 
    .ai_direct_question_row .question_container {
        margin-bottom: 8px;
    }
}
 
@media screen and (max-width: 425.98px) {
    .buttons_container>div {
        display: block !important;
    }
    .buttons_container>div button {
        width: 100%;
    }
    .buttons_container>div button:first-child {
        margin-bottom: 8px;
    }
}
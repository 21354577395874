.back_btn {
    min-width: 100px;
    min-height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    /* border-radius: 8px; 
    border: 2px solid var(--cerulean);
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

/* .back_btn:hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
} */

.back_btn .icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.back_btn .label {
    margin: 0;
}

@media screen and (max-width: 768px) {
    .back_btn {
        border-width: 1px;
    }
}

@media screen and (max-width: 425.98px) {
    .back_btn {
        min-height: 40px;
    }
}
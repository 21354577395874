.agentcard_container{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid var(--grey-goose);
    padding: 24px 32px;
    background: var(--pure-white);
}
.agentcard_container .agentcard_row {
    margin: 0;
}

.imageContainer{
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.imageContainer img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.username {
    font-weight: bold;
    width: 100%;
    font-size: 18px;
    line-height: 20px;
}
.agentcard_container .agentcard_row .first_column {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
}
.agentcard_container .agentcard_row .agentlocation{
    padding-right: 0;
    background-color: white;
}
.agentcard_container .agentcard_row .agentlocation>p{
    line-height: 20px;
}

.agentcard_container .agentcard_row .second_column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}
.icon_container{
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 8px;
}
.agentcard_container .agentcard_row .textcategory {
    display: inline-block;
    color: var(--cyan-blue);
    margin-bottom: 8px;
    margin-right: 8px;
}
.agentcard_container .agentcard_row .divider{
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 16px;
    margin-top: 8px;
}
.agentcard_container .agentcard_row .icon {
    color: var(--cyan-blue);
    display: inline-block;
    height: auto;
    margin-right: 5px;
}
.agentcard_container .agentcard_row .paragraph{
    width: 100%;
    margin: 0;
    line-height: 20px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
.agentcard_container .agentcard_row .third_column {
    padding-top: 38px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.agentcard_container .agentcard_row .third_column .experience_container {
    margin-bottom: 16px;
}
.agentcard_container .agentcard_row .third_column .third_columnTitle {
    font-weight: 600;
    color: var(--granite);
}
.agentcard_container .agentcard_row .third_column .third_columnTitle>span {
    display: none;
}
.agentcard_container .agentcard_row .third_column .third_columnTitleValue {
    font-weight: 600;
}


.viewProfileBtn {
    padding: 8px;
    margin: 0;
    width: 110px;
}
.viewProfileBtn p {
    font-size: 14px;
}

.experience{
    display:flex; 
    flex-direction:column;
    padding-bottom: 32px;
    text-align: center;
}

.hide{
    display: none;
}


@media (min-width: 1400px) {    
    .agentcard_container>div>div:first-child {
        max-width: 22%;
        flex: 0 0 22%;
    }
    .agentcard_container>div>div:nth-child(2) {
        max-width: 52%;
        flex: 0 0 52%;
    }
    .agentcard_container>div>div:last-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
}
@media (max-width: 1399.98px) {
    .agentcard_container{
       padding: 16px 24px;
    }    
   .agentcard_container>div>div:first-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .agentcard_container>div>div:nth-child(2) {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .agentcard_container>div>div:last-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
}
@media (max-width: 1200px) { 
     .agentcard_container{
        padding: 16px;
    }    
    .imageContainer {
        width: 105px;
        height: 105px;
    }
    .second_column {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 991.98px) {
    .agentcard_container{
        max-width: 100%;
        padding: 32px 40px;
    }     
    .imageContainer {
        width: 150px;
        height: 150px;
    }
    .second_column{
        padding-right: 32px;
        padding-left: 32px;
    }
    .divider{
        margin-top: 16px;
    }
    .agentcard_container>div>div:first-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .agentcard_container>div>div:nth-child(2) {
        max-width: 55%;
        flex: 0 0 55%;
    }
    .agentcard_container>div>div:last-child {
        max-width: 20%;
        flex: 0 0 20%;
    }
}
@media (max-width: 767.98px) {
    .agentcard_container{
        padding: 24px;
    } 
    .imageContainer {
        width: 130px;
        height: 130px;
    }
    .second_column {
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (max-width: 618.98px) {
    .imageContainer {
        width: 120px;
        height: 120px;
    }
    .agentcard_container>div>div:first-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .agentcard_container>div>div:nth-child(2) {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .agentcard_container>div>div:last-child {
        max-width: 25%;
        flex: 0 0 25%;
    }
}
@media (max-width: 575.98px) {
    .imageContainer{
        width: 140px;
        height: 140px;
        margin-bottom: 8px;
    }
    .agentcard_container{
        max-width: 100%;
        padding: 8px;
    }    
    .agentcard_container>div{
        display: block;
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }
    .agentcard_container>div>div:first-child,
    .agentcard_container>div>div:nth-child(2),
    .agentcard_container>div>div:last-child,
    .agentcard_container>div>div {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .second_column{
        display: block;
    }
    .username {
        text-align: center;
        font-size: 18px;
    }
    .agentcard_container .agentcard_row .third_column {
        padding-top: 0px;
        display: block;
        text-align: center;
    }
    .middle_align{
        text-align: center;
    }
    .viewProfileBtn{
        width: auto;
        padding: 0 20px;
        min-height: 35px;
    }
    .button_container{
        display: flex;
        justify-content: center;
    }
    .agentcard_container .agentcard_row .third_column .experience_container {
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .experience {
        flex-direction: row;
        padding: 0 12px;
    }
    .third_columnTitle {
        padding-right: 8px;
    }
    .third_columnTitleValue {
        padding-left: 8px;
    }
    .divider{
        margin-top: 0;
        margin-bottom: 8px;
    }
    .icon_container{
        margin-bottom: 0;
    }
    .third_columnTitle>span {
        display: unset;
    }
    .agentlocation{
        justify-content: center !important;
    }

  }
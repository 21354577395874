.skill_budge_icon {
    padding: 3px 6px 2px; 
    border-radius: 6px;
    font-weight: 600;
    justify-content: center;
    color: #F9F9F9;
}

.skill{
    margin: 0 8px 8px 0;
    display: inline-block;
}

.blue_color{
    color:rgba(72, 118, 210, 1);
    background-color:rgba(72, 118, 210, 0.2);
}
.green_color{
    color: rgba(69, 152, 76, 1);
    background-color: rgba(69, 152, 76, 0.2);
}
.red_color{
    color: rgba(188, 51, 36, 1);
    background-color: rgba(188, 51, 36, 0.2);
}
.purple_color{
    color: rgba(86, 69, 156, 1);
    background-color: rgba(86, 69, 156, 0.2);
}

.brown_color{
    color: rgba(169, 93, 36, 1);
    background-color: rgba(169, 93, 36, 0.2);
}
.grey_color{
    color: rgba(128, 128, 128, 1);
    background-color: rgba(128, 128, 128, 0.2);
}
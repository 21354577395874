.text_icon {
    border-radius: 15px;
    font-weight: 600;
    background-color: var(--alice-blue);
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}
.text_icon .icon {
    width: 20px; 
    padding-right: 8px; 
}
.text_icon .text {
    margin: 0;
}
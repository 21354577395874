.base {
    display: inline-block;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--mandatory-red);
    background-color: transparent;
    cursor: pointer;
}
.base .text {
    margin: 0;
}
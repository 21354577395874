.general_textbox {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--granite);
    border-radius: 5px;
    background-color: var(--pure-white);
    height: 45px;
}

.general_textbox .front_icon {
    margin: auto;
    width: 25px;
    height: 25px;
}

.general_textbox .text_input {
    width: 90%;
    padding: 16px 0px;
    padding-right: 32px;
    outline: none;
    border: none;
    border-radius: 5px;
}

.general_textbox .text_input_100 {
    width: 100%;
    padding: 8px 8px;
    outline: none;
    border: none;
    border-radius: 5px;
}

.general_textbox .clean_text_icon {
    cursor: pointer;
    position: absolute;
    right: 8px;
    width: 15px;
    height: 15px;
}

.general_textbox:focus-within {
    border: 1px solid var(--cerulean);
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.10);
    background-color: var(--pure-white);
}
.direct_question_mi {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
}
.direct_question_mi>div {
    display: flex;
    align-items: center;
}
.direct_question_mi>div:hover {
    border: 1px solid var(--cerulean);
}
.direct_question_mi .answer {
    cursor: pointer;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid var(--alice-blue);
    background-color: var(--alice-blue);
    box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.1);
}
.direct_question_mi .selected_answer {
    cursor: pointer;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid var(--cerulean);
    background-color: var(--cerulean);
    box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.1);
}
.direct_question_mi .label {
    flex: 1;
    margin: 0;
}
.direct_question_mi .selected_icon {
    width: 18px;
    height: 18px;
    margin-left: 8px;
}
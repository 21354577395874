.schedule_meetup_step3 .schedule_meet_table {
    border: 1px solid var(--granite);
}

.schedule_meetup_step3 .schedule_meet_table tr td {
    padding: 10px 5px;
    border: 1px solid var(--granite);
    vertical-align: top;
}

.schedule_meetup_step3 .schedule_meet_table tr td:first-child {
    width: 20%;
    padding-left: 20px;
    background: var(--cyan-blue);
    color: var(--pure-white);
    border-bottom-color: var(--white);
}

.schedule_meetup_step3 .schedule_meet_table tr:last-child td:first-child {
    border-bottom-color: var(--granite);
}

.schedule_meetup_step3 .schedule_meet_table tr td:last-child {
    padding-left: 20px;
    background: var(--pure-white);
}

.schedule_meetup_step3 .schedule_meet_notice_box {
    background-color: var(--alice-blue);
    padding: 16px 16px 6px;
    margin-bottom: 8px;
    border: 1px solid var(--granite);
    font-size: 12px;
    height: 150px;
    overflow: auto;
}

.schedule_meetup_step3 .schedule_meet_notice_box p {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.schedule_meetup_step3 .schedule_meet_notice_ttl {
    color: var(--cyan-blue);
    font-size: 18px;
    font-weight: bold;
}

.schedule_meetup_step3 .schedule_meet_notice_title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
}

.schedule_meetup_step3 .schedule_meet_notice_sub_title {
    font-weight: bold;
}

/* .schedule_meetup_step3 .indent {
    margin-left: 8px;
} */

.schedule_meetup_step3 .buttons_container {
    width: 100%;
    padding-top: 40px;
    /* padding-bottom: 30px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule_meetup_step3 .agreement_col {
    padding-top: 18px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.schedule_meetup_step3 .agreement_col .agreement_cb {
    font-size: 16px;
}

.schedule_meetup_step3 .agreement_col .agreement_cb>label>span {
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 900px) {
    .schedule_meetup_step3 .schedule_meet_table tr td:first-child {
        width: 25%;
    }
}

@media screen and (max-width: 700px) {
    .schedule_meetup_step3 .schedule_meet_table tr td:first-child {
        width: 30%;
    }
}

@media screen and (max-width: 575.98px) {
    .schedule_meet_table {
        margin-bottom: 16px !important;
    }

    .schedule_meetup_step3 .schedule_meet_notice_box,
    .schedule_meetup_step3 .schedule_meet_table tr td:first-child,
    .schedule_meetup_step3 .schedule_meet_table tr td {
        padding: 8px;
    }

    .schedule_meetup_step3 .buttons_container {
        padding-top: 16px;
    }

    .schedule_meetup_step3 .schedule_meet_notice_box {
        margin-bottom: 8px;
    }

    .schedule_meetup_step3 .agreement_col .agreement_cb>label>span {
        width: 18px;
        height: 18px;
    }

    .schedule_meetup_step3 .agreement_col .agreement_cb>label>span::after {
        left: 6px;
        top: 3px;
    }
}

@media screen and (max-width: 425.98px) {
    .schedule_meetup_step3 .agreement_col .agreement_cb {
        width: auto;
        margin: 0 auto;
    }

    .buttons_container {
        display: block !important;
    }

    .buttons_container button {
        width: 100%;
    }

    .buttons_container button:first-child {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 400px) {
    .schedule_meetup_step3 .schedule_meet_table tr td:first-child {
        width: 40%;
    }
}
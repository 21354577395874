.schedule_meetup_step1 {
    width: 100%;
}

.schedule_meetup_step1 .top {
    padding-bottom: 32px;
    border-bottom: 1px dashed var(--grey-goose);
}

.schedule_meetup_step1 .top>div {
    width: 100%;
    padding-bottom: 24px;
}
.schedule_meetup_step1 .top>div:last-child {
    padding-bottom: 0;
}
.schedule_meetup_step1 .top .top_label {
    margin: 0;
    margin-bottom: 20px;
}
.schedule_meetup_step1 .top>div:first-child .top_label{
    padding-right: 32px;
}
.schedule_meetup_step1 .mandatory{
    margin-left: 8px;
}
.schedule_meetup_step1 .top .mandatory {
    margin-right: 24px;
    margin-bottom: 24px;
}
.schedule_meetup_step1 .top>div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}

.appointment_method{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}

.appointment_method> li{
    margin: 0 24px 0 0px;
}

.appointment_method .talking_demand label{
    padding-left: 28px;
    padding-right: 16px;
}

.schedule_meetup_step1 .top .error_message {
    width: 100%;
}
.schedule_meetup_step1 .top .agent_before_radio_button,
.schedule_meetup_step1 .top .appointment_methods .appointment_method {
    padding: 0 24px 0 0px;
}
.appointment_method_radio_button{
    padding: 0 24px 0 0px;
}
.bottom_margin{
    margin-bottom: 8px;
}
.schedule_meetup_step1 .middle {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    padding: 32px 0;
    border-bottom: 1px dashed var(--grey-goose);
}

.schedule_meetup_step1 .middle .question {
    margin-bottom: 20px;
}
.schedule_meetup_step1 .middle .mandatory {
    margin-bottom: 24px;
}

.schedule_meetup_step1 .middle .insurance_types_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.schedule_meetup_step1 .middle .insurance_types_container .insurance_type {
    padding-right: 16px;
    margin-bottom: 8px;
}
.schedule_meetup_step1 .middle .insurance_types_container .insurance_type>label {
    padding: 0 0 0 28px;
}
.schedule_meetup_step1 .middle .insurance_types_container .insurance_type>label>span {
    top: 50%;
    transform: translateY(-50%);
}

.schedule_meetup_step1 .bottom {
    padding-top: 32px;
}

.schedule_meetup_step1 .bottom .request_textbox_label {
    margin-bottom: 16px;
}

.schedule_meetup_step1 .bottom .buttons_container {
    width: 100%;
    padding-top: 48px;
    display: flex;
    justify-content: space-between;
}

.discussStatus_radio {
    margin-bottom: 8px !important;
}

@media screen and (max-width: 767.98px) {
}
@media screen and (max-width: 575.98px) {
    .schedule_meetup_step1 .top .top_label {
        margin-bottom: 4px;
    }
    .schedule_meetup_step1 .top .mandatory {
        margin-bottom: 8px;
    }
    .schedule_meetup_step1 .top>div:first-child .top_label {
        width: 100%;
    }
    .schedule_meetup_step1 {
        padding-bottom: 2px;
    }
    .schedule_meetup_step1 .top {
        padding-bottom: 16px;
    }
    .schedule_meetup_step1 .middle .insurance_types_container .insurance_type {
        margin-bottom: 8px;
    }
    .schedule_meetup_step1 .middle {
        padding: 16px 0;
    }
    .schedule_meetup_step1 .bottom {
        padding-top: 16px;
    }
    .schedule_meetup_step1 .bottom .buttons_container {
        padding-top: 24px;
    }
    .schedule_meetup_step1 .middle .question {
        margin-bottom: 12px;
    }
    .schedule_meetup_step1 .middle .mandatory {
        margin-bottom: 16px;
    }
    .schedule_meetup_step1 .top>div {
        padding-bottom: 8px;
    }
    .schedule_meetup_step1 .top>div li {
        margin-bottom: 8px;
    }
}
@media screen and (max-width: 425.98px) {
    /* .schedule_meetup_step1 .top .top_label:first-child {
        width: 100%;
    } */
    
    .buttons_container {
        display: block !important;
    }
    .buttons_container button {
        width: 100%;
    }
    .buttons_container button:first-child {
        margin-bottom: 8px;
    }
}
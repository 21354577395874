.generalcard_container {
    /* padding: 20px !important; */
    padding: 48px 40px !important;    
    background: var(--pure-white);
}

.top {
    display: flex; 
    flex-direction: column;
    margin-bottom: 32px;
    align-items: center;
    border-bottom: var(--profile-border-color);
}

.profileImageContainer {
    width: 120px;
    height: 120px; 
    border-radius: 50%;
    overflow: hidden;
    border: 0.5px solid var(--grey-goose);
}

.username {
    font-size: 20px;
    font-weight: bold;
    margin: 16px 0;
}

.scheduleMeetupButton {
    height: 35px;
    min-width: 120px;
}


.profileImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.middle>label {
    display: block;
}

.title {
    margin-bottom: 8px; 
    color: var(--granite);
}

.branchName {
    color: var(--cerulean);
    padding: 0;
}

.sub_branch_name {
    margin-bottom: 24px;
    font-weight: 600;
}

.titleValue {
    margin-bottom: 24px; 
    white-space: pre-wrap;
}

.skill_container {
    margin-bottom: 16px; 
    font-weight: bold;
}
@media only screen and (max-width: 991.98px) {
    .generalcard_container {
        padding: 32px 16px !important;
    }
    .username {
        margin: 8px 0;
    }
}
@media only screen and (max-width: 767.98px) {
    .generalcard_container {
        padding: 24px 32px !important;
    }
    .top {
        margin-bottom: 24px;
    }
}
@media only screen and (max-width: 575.98px) {
    .generalcard_container {
        padding: 16px 8px !important;
    }
    .title {
        margin-bottom: 4px; 
    }

    .titleValue {
        margin-bottom: 8px; 
    }
    .skill_container {
        margin-bottom: 0; 
    }
    .top {
        margin-bottom: 16px;
    }

    .sub_branch_name {
        margin-bottom: 8px;
    }
}

.next_button {
  min-width: 100px;
  min-height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  color: white;
  background-color: var(--cerulean);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid var(--ocean);
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.next_button .icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.next_button .text {
  margin: 0;
}
.next_button:hover {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.disabled_next_btn {
  min-width: 100px;
  min-height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  color: white;
  background-color:gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid gainsboro;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.disabled_next_btn .icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.disabled_next_btn .text {
  margin: 0;
}

.next_button .loading_icon,
.disabled_next_btn .loading_icon {
  width: 15px;
  height: 15px; 
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .next_button {
    border-width: 1px;
  }
  .disabled_next_btn {
    border-width: 1px;
  }
}

@media screen and (max-width: 425.98px) {
    .next_button,
    .disabled_next_btn {
        min-height: 40px;
    }
}

.appointment_stepper {
    display: flex;
    list-style: none;
    margin: 0 0 40px 0;
    padding: 0;
}
.appointment_stepper>li {
    background: url('../../../assets/images/stepper_bg.png') no-repeat top center;
    background-size: 100% 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 270px;
    font-weight: bold;
    position: relative;
    margin: 0 16px 0 12px;
    color: var(--grey-goose);
}
.appointment_stepper>li:nth-child(2) {
    min-width: 230px;
}
.appointment_stepper>li:last-child {
    margin-right: 0;
}
.stepper_text {
    margin-left: 8px;
}
.stepper_box {
    background: var(--grey-goose);
    color: var(--pure-white);
    padding: 4px 16px;
    height: 45px;
    max-height: 45px;
    position: relative;
    left: -12px;
    border-radius: 10px 18px 16px 10px;
    display: flex;
    align-items: center;
    z-index: 9;
    font-weight: normal;
}
.stepper_box:after {
    content:'';
    transform: rotate(  45deg );
    background: var(--grey-goose);
    width: 40px;
    height: 40px;
    /* top: 6px; */
    right: -8px;
    position: absolute;
    border-radius: 15px;
    z-index: -1;
}
.appointment_stepper>li.active  { 
    color: var(--dune);
}
.appointment_stepper>li.active .stepper_box,
.appointment_stepper>li.active .stepper_box:after  {
    background: var(--cerulean);
}


@media (max-width: 767.98px) {  
    .appointment_stepper {
        margin-bottom: 24px;
        width: 85%;
    }      
    .appointment_stepper>li {
        background: initial;
        width: 30%;
        height: auto;
        min-height: auto;
        margin-right: 24px;
        margin-left: 0;
    }
    /* .appointment_stepper>li.selected {
        width: 40%;
    } */
    /* .appointment_stepper>li:first-child,
    .appointment_stepper>li:last-child {
        width: 30%;
    } */
    .appointment_stepper>li:nth-child(2) {
        width: 40%;
        min-width: auto;
    }
    .stepper_text {
        display: none;
    }
    .stepper_box {
        position: initial;
        width: 100%;
        height: 38px;
        max-height: 38px;
        display: flex;
        justify-content: center;
        border-radius: 10px 15px 15px 10px;    
        padding: 4px 8px;
    }
    .stepper_box:after {
        width: 29px;
        height: 29px;
        top: 5px;
        right: -5px;
        border-radius: 8px;
    }
}
@media (max-width: 575.98px) { 
    .appointment_stepper {
        min-width: 282px;
    }
    .appointment_stepper>li {
        width: 25%;
        margin-right: 16px;
    }
    .appointment_stepper>li:nth-child(2) {
        width: 25%;
    }
    .appointment_stepper>li.selected {
        width: 50%;
    }
}
.disabled_submit_btn {
    min-width: 100px;
    min-height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: white;
    background-color:gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid gainsboro;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.disabled_submit_btn .icon {
    width: 15px;
    height: 15px; 
    margin-right: 5px;
}
.disabled_submit_btn .text {
    margin: 0;
}


.submit_button {
    min-width: 100px;
    min-height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: var(--pure-white);
    font-weight: 600;
    background-color: var(--apple-green);
    display: flex; 
    justify-content: center; 
    align-items: center;
    border-radius: 8px; 
    border: 2px solid var(--leaf);
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.submit_button .icon {
    width: 15px;
    height: 15px; 
    margin-right: 5px;
}
.submit_button .text {
    margin: 0;
}
.submit_button:hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

@media screen and (max-width: 768px) {
    .submit_button,
    .disabled_submit_btn {
        border-width: 1px;
    }
}

@media screen and (max-width: 425.98px) {
    .submit_button,
    .disabled_submit_btn {
        min-height: 40px;
    }
}
.search_form {
  width: 100%;
  padding: 56px 32px;
  /* padding-top: 40px;
    padding-bottom: 30px; */
  border-radius: 5px;
  background-color: var(--pure-white);
  box-shadow: 0px 2px 14px 1px var(--box-shadow);
}
.search_form > div {
  margin-top: 24px;
}
.search_form > div:nth-of-type(2) {
  margin-top: 0;
}
.search_form label {
  padding: 0 0 24px 28px;
}
.search_form
  .insurance_types_container
  .insurance_types
  .insurance_type
  label
  > span {
  top: 0px !important;
}
.title {
  margin: 0;
  line-height: 30px;
}

.search_form .insurance_types_container {
  margin-top: 32px;
}

.search_form .sub_title_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  height: "180px";
}

.search_form .sub_title_container .sub_title {
  margin: 0;
  /* color: var(--gray); */
}

.search_form .sub_title_container .mandatory {
  margin-left: 8px;
  margin-bottom: 2px;
}

.search_form .insurance_types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search_form .insurance_type {
  padding-right: 32px;
}

.search_form .insurances_error {
  margin-top: -16px;
  margin-bottom: 16px;
}

.search_form .next_btn_container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991.98px) {
  .search_form {
    padding: 56px 48px;
  }
}
@media screen and (max-width: 767.98px) {
  .search_form {
    padding: 56px 32px;
  }
}
@media screen and (max-width: 576px) {
  .search_form {
    padding: 24px 16px;
  }
  .search_form label {
    padding: 0 0 16px 28px;
  }
  .search_form > div {
    margin-top: 16px;
  }
  .search_form .insurance_types_container,
  .search_form .next_btn_container {
    margin-top: 24px;
  }
  .search_form .insurance_type {
    padding-right: 16px;
  }
  .search_form
    .insurance_types_container
    .insurance_types
    .insurance_type
    label
    > span {
    top: 2px !important;
  }
}
@media screen and (max-width: 425.98px) {
  .search_form .next_btn_container button {
    width: 100%;
  }
}
@media screen and (max-width: 325.98px) {
  .search_form .insurance_types_container .insurance_types .insurance_type {
    width: 100%;
  }
}

.thanks_page {
    background-color: var(--white-lilac);
}
.thanks_page .title {
    font-size: 38px;
    margin-top: 64px;
    margin-bottom: 48px;
    color: var(--cerulean);
}
.thanks_page .sub_title {
    margin-bottom: 24px;
}
.thanks_page .email_address {
    color: var(--cerulean);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 20px;
    pointer-events: none;
}
.thanks_page .ok_btn {
    width: 120px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid var(--ocean);
    margin: 80px auto 0;
}

@media screen and (max-width: 1300px) {
    .thanks_page .title {
        font-size: 32px;
    }
}

@media screen and (max-width: 768px) {
    .thanks_page .title {
        font-size: 24px;
        margin: 64px 0 48px;
    }

    .thanks_page .sub_title {
        margin-bottom: 16px;
    }

    .thanks_page .sub_title .email_address {
        font-size: 18px;
    }

    .thanks_page .ok_btn {
        border-width: 1px;
        margin: 48px auto 0;
    }
}

@media screen and (max-width: 370px) {
    .thanks_page .title {
        font-size: 20px;
        margin: 32px 0 24px;
    }

    .thanks_page .sub_title {
        margin-bottom: 8px;
    }

    .thanks_page .sub_title .email_address {
        font-size: 16px;
    }

    .thanks_page .ok_btn {
        border-width: 1px;
        margin: 24px auto 0;
    }
}
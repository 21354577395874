.main_logo{
    width: 96px;
}

.main_logo .logo_image {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 575.98px) {
    .main_logo{
        width: 60px;
    }
}
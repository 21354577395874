.general_textbox {
    padding: 16px;
    width: 100%;
    border-radius: 5px; 
    outline: none; 
    border: 1px solid #D1D1D1;
    display: flex;
    background-color: var(--pure-white);
}

.general_textbox .general_textinput {
    width: 100%;
    outline: none;
    border: none;
}
@media (max-width: 991.98px) {
    .general_textbox {
        padding: 24px;
    }
}
@media (max-width: 575.98px) {
    .general_textbox {
        padding: 8px;
    }
}
.header{
    margin-top: 16px;
    margin-bottom: 0;
}

.wrap_text{
    white-space: pre-wrap;
}

.agent_detail {
    padding-bottom: 56px;
}

.col_container:nth-child(2) {
    padding-left: 65px;
}

.customtab_container{
    display: flex;
}

.header_container {
    padding-top: 32px;
    padding-bottom: 40px;
}

.title {
    color: var(--granite);
}

.description_card {
    background: var(--pure-white);
    padding: 48px 64px;
}
.social{
    display: inline-block;
    padding-bottom: 7px;
}

.social>a>img{
    width: 30px;
    height: 30px;
    margin-right: 16px;
    filter: drop-shadow(2px 2px 4px rgba(51, 51, 51, 0.2));
}

 .title{
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
}
.facebook{
    border-radius: 8px;
}
.value{
    color: #333333;
    padding-bottom: 7px;
}

.textcategory {
    display: inline-block;
    color: var(--cyan-blue);
    margin: 4px 8px 4px 0;    
}

.wrap_tabs{
    margin-top: 25px;
    /* padding: 10px; */
}
.wrap_tabs>div {
    width: 100%;
}
.MuiTabs-scroller,
.wrap_tabs .MuiTabs-fixed {
    overflow: inherit !important;
}
.description_box{
    padding: 32px 0 !important;
}

.hide{
    display: none;
}

.badge{
    color:var(--cerulean);
    font-size: 12px;
}


@media (min-width: 1200px) {        
    .col_container:nth-child(1) {
        max-width: 380px;
        flex: 380px;
    }
    .col_container:nth-child(2) {
        max-width: 770px;
        flex: 770px;
    }
}
@media (min-width: 1400px) {        
    .col_container:nth-child(2) {
        max-width: 818px;
        flex: 818px;
    }
}

@media (max-width: 1399.98px) {    
    .col_container:nth-child(2) {
        padding-left: 17px;
    }
}
@media (max-width: 1200px) {  
    .col_container:nth-child(2) {
        padding-left: 9px;
        max-width: 66.66%;
        flex: 66.66%;
    }
    .description_card {
       padding: 48px 32px;
    }
}
@media only screen and (max-width: 991.98px) {
    /* .col_container:nth-child(2) {
        padding-left: 26px;
    } */
    .description_card {
       padding: 32px 16px;
    }
    
}
@media only screen and (max-width: 767.98px) {
    .col_container:nth-child(2) {
        padding-left: 15px;
        margin-top: 16px;
        max-width: 100%;
        flex: 100%;
    }
    .description_card {
       padding: 24px 32px;
    }
}
@media only screen and (max-width: 575.98px) {
    .header{
        margin-top: 8px;
    }
    .description_card {
       padding: 16px 8px;
    }
    .header_container {
        padding-top: 16px;
        padding-bottom: 24px;
    }
    .agent_detail {
        padding-bottom: 32px;
    }
    .agent_detail_media_box {
        margin-bottom: 8px;
    }
    .agent_detail_media_box p:first-child {
        margin-bottom: 0;
    }
    .social>a>img{
        width: 25px;
        height: 25px;
        margin-right: 8px;
    }
}
.agent_detail_media_box {
    margin-bottom: 16px;
    padding-right: 8px;
    border: 1px solid #D1D1D1;
    border-radius: 0px 12px 12px 12px;
    box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.1);
}
.agent_detail_media_box:last-child {
    margin-bottom: 0;
}
.agent_detail_media_box p {
    margin-bottom: 0;
}
.agent_detail_media_box p:first-child {
    color: var(--cerulean);
    border-left: 4px solid var(--cerulean);
    padding: 8px 0 8px 8px;
    margin: -1px 0 4px -1px;
    font-weight: bold;
}
.agent_detail_media_box p:last-child {
    padding: 0 0 8px 28px;
}

@media only screen and (max-width: 575.98px) {
    
    .agent_detail_media_box {
        margin-bottom: 8px;
    }
    .agent_detail_media_box p:first-child {
        margin-bottom: 0;
    }
}
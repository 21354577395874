.ai_img_box_row .question_container {
    margin-bottom: 24px;
}
.ai_img_box_row .question_container h5 {
    display: inline;
    vertical-align: bottom;
    margin-right: 8px;
}
.ai_img_box_row>div:nth-child(1n+2) {
    margin-bottom: 32px;
}
.ai_img_box_row>div:nth-child(2n+2) {
    padding-right: 12px;
}
.ai_img_box_row>div:nth-child(2n+3) {
    padding-left: 12px;
}
.ai_img_box_row img {
    cursor: pointer;
}
.ai_img_box_row img.active {
    border: 6px solid var(--cyan-blue);
}
.buttons_container .find_agent_btn {
    height: 35px;
    border-radius: 8px;
    border: 2px solid var(--ocean);
}

@media screen and (max-width: 991.98px) {
    .buttons_container {
        margin-top: 32px;
    }
    .ai_img_box_row {
        margin-bottom: 16px;
    }   
    .ai_img_box_row>div:nth-child(1n+2) {
        margin-bottom: 16px;
    }
    .ai_img_box_row>div:nth-child(2n+2) {
        padding-right: 16px;
    }
    .ai_img_box_row>div:nth-child(2n+3) {
        padding-left: 16px;
    } 
}
@media screen and (max-width: 767.98px) { 
    .buttons_container {
        margin-top: 16px;
    } 
    .ai_img_box_row {
        margin-bottom: 8px;
    }  
    .buttons_container .find_agent_btn {
        border-width: 1px;
    }
    .ai_img_box_row img.active {
        border-width: 4px;
    }  
}
@media screen and (max-width: 575.98px) {
    .ai_img_box_row>div:nth-child(2n+2) {
        padding-right: 4px;
    }
    .ai_img_box_row>div:nth-child(2n+3) {
        padding-left: 4px;
    }
    .ai_img_box_row>div:nth-child(1n+2) {
        margin-bottom: 8px !important;
    } 
    .ai_img_box_row .question_container {
        margin-bottom: 8px;
    }
    .ai_img_box_row img.active {
        border-width: 4px;
    }
}
@media screen and (max-width: 425.98px) {
    .buttons_container .find_agent_btn {
        height: 40px;
    }
    .buttons_container>div {
        display: block !important;
    }
    .buttons_container>div button {
        width: 100%;
    }
    .buttons_container>div button:first-child {
        margin-bottom: 8px;
    }
}
/* @media screen and (max-width: 375px) {
    .buttons_container .find_agent_btn {
        height: 35px;
    }
} */
.text_icon {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 8px;
    padding-bottom: 5px;
}
.icon {
    width: 30px; 
    padding-right: 8px; 
}
.text {
    padding-top: 3px;
    margin: 0;
}
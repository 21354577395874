.ai_matching_page {
    /* background: linear-gradient(180deg, rgba(0, 165, 217, 0.9), rgba(0, 165, 217, 0.9)), url('../../assets/images/ai_matching_bg.jpg') top center no-repeat; */
    background: url('../../assets/images/ai_matching_bg.jpg') top center no-repeat;
    background-size: cover;
    padding: 32px 0 48px;
    /* min-height: calc(100vh - 110px); */
}
.ai_matching_page .title {
    color: var(--pure-white);
    margin-bottom: 32px;
}
.ai_matching_page .ai_main_box {
    padding: 48px;
    background-color: var(--pure-white);
    border-radius: 7px;
    margin: 0 auto;
}

@media screen and (min-width: 1211px) { 
    .ai_matching_page>.container {
        max-width: 1083px;
    }  
    .ai_matching_page .ai_main_box {
        max-width: 100%;
        flex: 100%;
    } 
} 
@media screen and (max-width: 1210.98px) { 
    .ai_matching_page>.container {
        max-width: 90%;
    }  
} 
@media screen and (max-width: 991.98px) {
    .ai_matching_page {
        padding: 32px 0 56px;
    }
    .ai_matching_page>.container {
        max-width: calc( 100vw - 80px);
    }
    .ai_matching_page .title {
        margin-bottom: 32px !important;
    } 
    .ai_matching_page .ai_main_box {
        padding: 48px;
    } 
}
@media screen and (max-width: 767.98px) {
    .ai_matching_page>.container {
        max-width: calc( 100vw - 48px);
    }
}
@media screen and (max-width: 575.98px) {
    .ai_matching_page {
        padding: 16px 15px;
    }
    .ai_matching_page>.container {
        max-width: 100vw;
    }
    .ai_matching_page .title {
        margin-bottom: 16px !important;
    }
    .ai_matching_page .ai_main_box {
        padding: 16px;
    }
}
@media screen and (max-width: 319px) {
    .ai_matching_page>.container {
    max-width: 320px;
    }
}
.custom_check_box {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
    padding-left: 25px;
    margin: 0;
    cursor: pointer;
}
.custom_check_box input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom_check_box .custom_check {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background: var(--pure-white);
    border: 1px solid var(--grey-goose);
    border-radius: 5px;
}
.custom_check_box input:checked ~ .custom_check {
    background: var(--cerulean);
    border-color: var(--cerulean);
}
.custom_check_box input:checked:hover ~ .custom_check {
    background: var(--ocean);
    border-color: var(--ocean);
}
.custom_check_box input:checked ~ .custom_check:after {
    display: block;
}
.custom_check_box .custom_check:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 4px;
    width: 5px;
    height: 9px;
    border: solid var(--pure-white);
    border-width: 0 2px 2px 0;
    transform: rotate( 45deg );
}
@media screen and (max-width: 575.98px) {    
    .custom_check_box .custom_check {
        width: 16px;
        height: 16px;
    }
    .custom_check_box .custom_check:after {
        left: 5px;
        top: 2px;
    }
    .custom_check_box {
        padding-left: 24px !important;
    }
}
.filter_all_container {
    width: 100%;
    align-items: center;
    border-radius: 12px;
    background: var(--pure-white);
    padding: 8px 20px;
}

.dropdown_container {
    display: flex;
    width: 100%;
    align-items: center;
}

.dropdown_container>div {
    margin-right: 16px;
}

.dropdown_container> :nth-child(4) {
    margin-right: 0px;
}

.filter_container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter_container>label {
    color: var(--granite);
}

.filter_container>label>span {
    color: initial;
}

.filter_container>label>span {
    margin-left: 8px;
}

.filter_container span,
.filter_container label {
    font-size: 14px;
}

.name_filter_padding {
    padding-top: 10px;
}

.name_filter {
    padding-left: 24px !important;
}

.name_filter>button {
    height: 40px;
}

.timeLabel {
    padding-right: 20px;
    display: inline-block;
    margin-bottom: 0;
    white-space: nowrap;
}

.label {
    display: inline-block;
    margin-bottom: 0;
}

.range_text {
    padding-right: 16px;
}

.start_time {
    display: flex;
    align-items: center;
}

.start_time> :first-child {
    margin-right: 8px;
}

.start_time {
    width: calc(100% - 44px);
}

.end_time {
    width: calc(100% - 44px);
}

.dropdown_position ul {
    top: 350%;
}

.filter_buttons {
    margin-right: 0px !important;
    display: flex;
    justify-content: space-between;
}

.filter_buttons button {
    margin-right: 16px;
}

.filter_buttons button:last-child {
    margin-right: 0px !important;
}

.filter_buttons button {
    height: 42px;
    min-width: 100px;
}

.filter_buttons button:nth-child(3) {
    height: 42px;
    min-width: 185px;
}

.first_row {
    display: flex;
}

@media (max-width: 1600.98px) {
    .filter_container {
        /* border: 1px solid rgba(0, 0, 0, 0.06); */
    }
    .filter_all_container {
        margin-top: 8px;
    }
    .filter_buttons {
        margin-right: 0px !important;
        display: flex;
        justify-content: space-between;
    }
    .filter_buttons button {
        margin-right: 8px;
    }
}

@media (max-width: 700.98px) {
    .filter_container {
        align-items: flex-start;
    }
    .dropdown_container {
        display: block;
    }
    .dropdown_container>div {
        margin-bottom: 8px;
    }
    .start_time {
        display: inline-block;
        width: calc(100% - 40px);
    }
    .end_time {
        width: calc(100% - 40px);
    }
    .name_filter {
        padding-top: 0px !important;
    }
}

@media (max-width: 575.98px) {
    .filter_buttons {
        flex-direction: column;
    }
    .filter_container {
        padding: 8px;
        display: flex;
        flex-direction: column;
    }
    .dropdown_container>div {
        margin-right: 0px;
    }
    .start_time {
        display: inline-block;
        width: calc(100% - 32px);
    }
    .end_time {
        width: calc(100% - 32px);
    }
    .timeLabel {
        padding-right: 8px;
    }
    .range_text {
        margin-left: 8px;
    }
    .filter_buttons button:first-child {
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .filter_buttons button {
        width: 100%;
    }
    .name_filter {
        padding-left: 0px !important;
    }
}
@charset "UTF-8";
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

body {
  margin: 0;
  min-width: 320px;
  font-family: 'Hiragino', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Verdana, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  background: #f6f7f9;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
}

p {
  margin: 0 0 8px 0;
}
a,
a:hover {
  text-decoration: none;
  color: #333;
}
img {
  max-width: 100%;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
h1 {
  font-size: 34px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 26px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}

:root{
  --dune: #333333;
  --granite: #808080;
  --white-lilac: #F6F7F9;
  --grey-goose: #D1D1D1;
  --grey-light: #F1F1F5;
  --alice-blue: #E9FAFF;
  --pure-white: #FFFFFF;
  --cerulean: #00A5D9;
  --ocean: #037599;
  --apple-green: #82C43C;
  --leaf: #69A926;
  --dirty-orange: #CB7200;
  --watermelon: #FF485A;
  --mandatory-red : #E2242C;
  
  --white: rgb(240, 240, 240);
  --gray: #808080;
  --black: rgb(58, 58, 58);
  --cyan-blue:  hsl(194, 100%, 43%);
  --light-blue: rgb(102, 193, 255);
  --red: rgb(206, 36, 36);
  --box-shadow: rgba(0, 0, 0, 0.06);
  --border-color: #E98300;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cmn-inner-width {
  margin: 0 auto;
  width: calc(100vw - 160px);
}
.cmn-height {
  min-height: calc(100vh - 110px);
}
.PrivateTabIndicator-root-5,
.PrivateTabIndicator-root-3 {
  bottom: -1px !important;
  height: 1px !important;
}

.MuiTabs-root,
.MuiTabs-fixed {
    overflow: inherit !important;
}
.Component-root-2[aria-selected="true"] {
  color: #40a9ff !important;
}

@media (max-width: 1399.98px){
  .cmn-inner-width {
    width: calc(100vw - 64px);
  }
}
@media (max-width: 991.98px){
  .cmn-inner-width {
    width: calc(100vw - 80px);
  }
}
@media (max-width: 767.98px){
  .cmn-inner-width {
    width: calc(100vw - 48px);
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  .font-16 {
    font-size: 14px;
  }
  /* .font-14 {
    font-size: 12px;
  }
  .font-12 {
    font-size: 10px;
  }
  *{
    font-size: 12px;
  } */
}
@media (max-width: 575.98px){
  .cmn-inner-width {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }  
  .cmn-height {
    min-height: calc(100vh - 95px);
  }
}
@media (max-width: 370px){
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  /* .font-16 {
    font-size: 12px;
  }
  .font-14 {
    font-size: 10px;
  }
  .font-12 {
    font-size: 10px;
  }
  *{
    font-size: 10px;
  } */
}

pre{
  white-space:pre-wrap;
  white-space:-moz-pre-wrap;
  white-space:-pre-wrap;
  white-space:-o-pre-wrap;
  word-wrap:break-word;
}
.gender_card {
    width: 100%; 
    height: 40px; 
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    list-style: none;
    border-radius: 8px;
    border: 1px solid var(--granite);
}

.gender_card .gender_item {
    width: 33%; 
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid var(--grey-goose);
}

.gender_card .gender_item:last-child {
    width: 34%; 
    border: none;
}

.gender_card .gender_item .gender_label {
    margin: 0;
    width: 100%;
    text-align: center;
}

.gender_card .gender_item .selected_gender_label {
    width: 100%;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    border-radius: 7px;
    border: 1px solid var(--cerulean);
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
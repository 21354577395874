.select_box {
  width: 100%;
  background: var(--pure-white);
  border: 1px solid #ddd;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}
.select_box:active,
.select_box:hover,
.select_box:focus {
  border-color: var(--dune);
}
.select_box:after {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 55%;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-color: var(--dune) transparent transparent transparent;
}
.select_box .blank_placeholder {
  height: 23px;
}
.select_box ul {
  background: var(--pure-white);
  width: 300px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 415%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.select_box ul.showbox {
  transition: all 0.2s ease-in-out;
}
.select_box ul.hidebox {
  visibility: hidden;
  opacity: 0;
}
.select_box ul.over_height {
  max-height: 300px;
  overflow-y: scroll;
}
.select_box ul > li {
  padding: 8px 16px;
  cursor: pointer;
}

.select_box ul > li:hover,
.select_box ul > li:focus {
  background-color: rgba(0, 0, 0, 0.04);
}

.button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
}

.clear_button {
  background-color: var(--pure-white);
  color: black;
  border: 2px solid #00a5d9;
  box-sizing: border-box;
  border-radius: 12px;
}

@media (max-width: 500px) {
  .select_box ul {
    left: 30%;
  }
}

.peroid_container{
    display: flex;
    width: 100%;
    padding-bottom: 16px;
}
.peroid_container>li{
    padding-left: 28px;
}
.time_duration{
    align-self: center;
    font-weight: bold;
}

.period{
    justify-content: flex-start;
    padding: 0;
    background-color: transparent;
}

@media screen and (max-width: 575.98px) {
    .peroid_container{
        padding-bottom: 8px;
    }
}

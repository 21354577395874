.mandatory{
  margin-left: 8px; 
}

.title {
  padding-bottom: 16px;
  font-weight: bold;
  display: block;
}
.calendar_card {
  background: #fff;
  padding: 32px 48px;
  height: calc( 100% -  40px);
}

.border {
  height: 95%;
  right: 0;
  border-right: 1px dashed var(--grey-goose);
  position: absolute;
  bottom: 0;
}  
.warning_msg {
  display: block;
  /* padding-top: 8px; */
  color: var(--mandatory-red);
}

.date_warning_msg {
  display: block;
  padding-top: 8px;
  color:var(--mandatory-red);
}
.method_fields{
  padding-bottom: 24px;
}

.schedule_fields {
  padding: 48px 0;
}
.field {
  padding-top: 24px;
}
.field:nth-child(1),
.field:nth-child(2) {
  padding-top: 0;
}   
.label {
  font-weight: bold;
  margin-bottom: 8px;
}  
.buttons_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty_timeslots_msg {
  width: 100%;
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
}

.loading_container .loading_icon {
  width: 50px;
  height: 50px;
}

.space_between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 200px;
}

.gender_radio{
  display: flex;
  height: 64px;
}

.gender_radio > li{
 margin: 8px 0px 8px 0px;
}

.gender_radio label{
  margin-right: 16px;
}

.store_dropdown{
  height: 60px;
}

.convenient_time{
  display: inline-block;
  height: 64px;
}
.convenient_time > li{
  margin: 8px 0px 8px 0px;
 }
 

.convenient_time label{
  margin-right: 16px;
}

.address_row{
  display: flex;
}

.address_row>div{
  width: 100%;
}

.address_row>div:first-child {
  margin-right: 16px;
  margin-bottom: 24px;
}

.address_second_row{
  margin-bottom: 24px;
}

.prefecture_dropdown{
    height: 60px;
}

.name_row{
  display: flex;
  justify-content: space-between;
}

.name_col{
  width: 100%;
}

.name_col:nth-child(1){
  margin-right: 16px;
  margin-bottom: 24px;
}

.column_flex{
  display: flex;
  flex-direction: column;
  min-height: 180px;
}

@media (max-width: 991.98px) {
  .prefecture_dropdown{
    height: 75px;
  }

  .store_dropdown{
    height: 75px;
  }

  .calendar_card {
    width: 100%;
    padding: 24px 32px;
    height: auto;
  }
  .calendar_child:first-child {
    margin-bottom: 32px;
  }
  .field:nth-child(2) {
    padding-top: 24px;
  }
  .border {
    position: initial;
    width: 100%;
    display: block;
    margin-top: 32px;
    height: 1px;
    border-bottom: 1px dashed var(--grey-goose);
    border-right: 0;
  }
}

@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  .prefecture_dropdown{
    height: 45px;
  }

  .store_dropdown{
    height: 45px;
  }

  .title {
    padding-bottom: 8px;
  }
  .calendar_card {
    padding: 16px 8px;
  }
  .calendar_child:first-child {
    margin-bottom: 16px;
  }
  .schedule_fields {
    padding: 32px 0 24px;
  }
  .field:nth-child(2) ,
  .field {
    padding-top: 16px;
  }
  .border {      
    margin-top: 16px;
  }
  .convenient_time{
    display: block;
    height: auto;
  }
  .convenient_time> li{
    margin: 16px 0px 6px 0px;
  }
}
@media screen and (max-width: 425.98px) {    
    .buttons_container {
        display: block !important;
    }
    .buttons_container button {
      width: 100%;
    }
    .buttons_container button:first-child {
        margin-bottom: 8px;
    }
}

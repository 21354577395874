.bread-crumb {
    font-size: 14px !important;
}

.bread-crumb .breadcrumb-label {
    font-size: 14px;
    color: var(--gray);
    cursor: pointer;
}

.bread-crumb .breadcrumb-last-label {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
}

@media (max-width: 767px){
    .bread-crumb {
        font-size: 12px !important;
    }
    .bread-crumb .breadcrumb-label {
        font-size: 12px;
    }
    .bread-crumb .breadcrumb-last-label {
        font-size: 12px;
    }
}
.header {
    margin-top: 8px;
    margin-right: 16px;
    margin-bottom: 0;
}

.badge {
    padding: 5px;
    color: var(--cyan-blue);
    margin-left: 5px;
}

.paragraph {
    font-style: normal;
    font-weight: normal;
}

.headerContainer {
    padding-top: 32px;
    padding-bottom: 40px;
}

.card_container {
    margin-bottom: 24px;
}

.card_container:nth-child(2n + 1) {
    padding-right: 28px;
}

.card_container:nth-child(2n + 2) {
    padding-left: 28px;
}

.pagination_box {
    padding-top: 40px;
    padding-bottom: 56px;
}

.pagination_box nav button {
    font-size: 14px;
}

.filter_container {
    background-color: var(--pure-white);
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    width: 100%;
}

.filter_border {
    border-right: 1px dashed var(--granite);
    margin: 16px 0px 16px 0px;
}

.filter_button {
    height: 55px;
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    margin-right: 16px;
    box-shadow: none !important;
}

.filter_button>img {
    margin-right: 0px !important;
}

.research_button {
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    margin-top: 0px;
    color: black;
    box-shadow: none !important;
    min-width: 165px;
    display: none;
}

.filter_bar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
}

.no-agent {
    text-align: center;
}

.filter_buttons {
    display: flex;
}

@media (max-width: 1600.98px) {
    .filter_border {
        display: none;
    }
    .filter_container {
        background-color: transparent;
        flex-direction: column;
        justify-content: flex-end;
    }
    .filter_button {
        display: none;
    }
}

@media (max-width: 1399.98px) {
    .card_container:nth-child(2n + 1) {
        padding-right: 12px;
    }
    .card_container:nth-child(2n + 2) {
        padding-left: 12px;
    }
}

@media (max-width: 1200px) {
    .card_container:nth-child(2n + 1) {
        padding-right: 8px;
    }
    .card_container:nth-child(2n + 2) {
        padding-left: 8px;
    }
}

@media (max-width: 991.98px) {
    .card_container:nth-child(2n + 1) {
        padding-right: 15px;
    }
    .card_container:nth-child(2n + 2) {
        padding-left: 15px;
    }
    .card_container {
        margin-bottom: 16px;
    }
    .pagination_box {
        padding-top: 16px;
    }
}

@media (max-width: 767.98px) {
    .filter_button {
        position: absolute;
        right: -16px;
        top: -40px;
        width: 45px;
        height: 33px;
        display: unset;
    }
    .research_button {
        display: unset;
    }
}

@media (max-width: 575.98px) {
    .research_button {
        margin-top: 8px;
    }
    .header {
        margin-bottom: 8px;
    }
    .headerContainer {
        padding-top: 16px;
        padding-bottom: 24px;
    }
    .pagination_box {
        padding-top: 8px;
        padding-bottom: 32px;
    }
    .card_container {
        margin-bottom: 8px;
    }
}
.branch-list-container {
    min-height: calc(100vh - 450px);
}

.branch-list-container>div:nth-child(2) {
    margin-top: 8px;
}

.no_branch {
    text-align: center;
    color: var(--red);
}

.branch-mi {
    margin-bottom: 8px;
}

.branch-mi:last-child {
    margin-bottom: 0px;
}

.buttons_container {
    margin-top: 16px;
}

.question_container {
    margin-bottom: 24px;
}

.question_container h5 {
    display: inline;
    vertical-align: bottom;
    margin-right: 8px;
}

/* @media screen and (max-width: 991.98px) {
    .buttons_container {
        margin-top: 32px;
    }
}

@media screen and (max-width: 767.98px) {
    .buttons_container {
        margin-top: 16px;
    }
} */
 
@media screen and (max-width: 425.98px) {
    .buttons_container>div {
        display: block !important;
    }
    .buttons_container>div button {
        width: 100%;
    }
    .buttons_container>div button:first-child {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 575.98px) {
    .question_container {
        margin-bottom: 8px;
    }
}
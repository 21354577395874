.header_bar {
    width: 100%; 
    height: 60px;  
    display: flex; 
    align-items: center; 
    justify-content: center;
    position: sticky; 
    top: -1px; 
    background-color: var(--pure-white); 
    border-bottom: 1px solid #D1D1D1;
    z-index: 999;
}

.header_bar .header_bar_container {
    height: 60px; 
    display: flex; 
    align-items: center; 
    justify-content: space-between;
}

.header_bar .main_logo {
    cursor: pointer;
}
.header_bar .flags_container>img {
    margin-right: 8px;
    width: 50px;
    max-height: 35px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid #D1D1D1;
}
.header_bar .flags_container>img:last-child {
    margin-right: 0;
}
.header_bar .flags_container .selected_flag_icon {
    background-color: #F1F1F5;
}
@media only screen and (max-width: 575.98px) {
    .header_bar ,
    .header_bar .header_bar_container {
        height: 45px; 
    }
    .header_bar .flags_container>img {
        width: 40px;
    }
    
}
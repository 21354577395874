.calendar {
    box-sizing: border-box;
    font-size: 1rem;
    max-width: 100%;
    margin: 0 auto;
}

.calendar .header {
    background-color: var(--pure-white);
    text-align: center;
    min-height: 2rem;
    line-height: 2rem;
    color: var(--black);
    font-family: var(--font-book);
    font-weight: 700;
    display: flex;
}


.calendar .header .previousContainer {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: left;
}

.calendar .header .currentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--cyan-blue);
}

.calendar .header .nextContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
}

.calendar .header .nextContainer .next {
    padding-left: 5px; 
    padding-right: 5px;
    cursor: pointer;
}

.calendar .header .previousContainer .previous{
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer
}

.calendar .day-names {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 10px;
    align-items: center;
}

.calendar .body {
    border: 1px solid var(--light-gray);
}


.calendar .header .previous {
    flex: 1;
    text-align: left;
}

.calendar .header .next {
    flex: 1;
    text-align: right;
}

.calendar .header .previous>span,
.calendar .header .next>span {
    border: 1px solid var(--gray);
    border-radius: 3px;
    padding: 2px 5px 2px;
}

.calendar .week {
    background-color: white;
    width: calc(100% / 7);
    height: 44px;
    padding: 2px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    color: var(--black);
    font-size: 14px;
    font-weight: bold;
}
.calendar .week div {
    width: 100%;
    background-color : var(--pure-white);
}
.calendar .week div.weekend {
    width: 100%;
    background-color : var(--pure-white);
    color:  var(--mandatory-red);
}

.calendar .days-container {
    display: flex; 
    flex-wrap: wrap;
}

.calendar .days-container .day {
    position: relative;
    width: calc(100% / 7);
    height: 44px;
    background-color: white;
    border: 1px solid var(--grey-light);
    margin: 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
    cursor: pointer;
}

.calendar .day div {
    width: 54px;
    height: 44px;
    position: relative;
    color: var(--black);
    z-index: 100;
    line-height: 44px;
    font-weight: bold;
}

.calendar .day div.sunday-before {
    color: rgb(245, 220, 220);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--white-lilac);
}

.calendar .day div.saturday-before {
    color: var(--cyan-blue);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--white-lilac);
}

.calendar .day div.offday {
    color: var(--mandatory-red);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--white-lilac);
}

.calendar .day div.before {
    color: var(--grey-light);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.calendar .day div.sunday-nextday{
    color: rgb(240, 130, 130);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--white-lilac);
}

.calendar .day div.nextday {
    color:gray;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.calendar .day div.selected {
    background-color: var(--cyan-blue);
    color: var(--white);
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.calendar .day div.today {
    background-color: var(--gray);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.calendar .day div.disable {
    color: var(--grey-light);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.calendar .day div.disabled_weekend {
    color: rgb(245, 220, 220);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--white-lilac);
}

.calendar .day div.default {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .calendar {
        max-width: 100%;
    }
}
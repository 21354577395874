.mandatory {
    display: inline-block;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--mandatory-red);
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid var(--mandatory-red);
}
.mandatory .text {
    margin: 0;
}
.container{
    display: flex;
    width: 100%;
    height: 60px;
}

.date_selection{
    display: flex;
    width: 100%;
    margin-right: 8px;
    align-items: center;
    height: 100%;
}

.date_selection>div{
    margin-right: 8px;
}

.dropdown_height{
    height: 100%;
}

@media (max-width: 991.98px) {
    .container{
      height: 75px;
    }
}

@media (max-width: 767.98px) {
 
}
@media (max-width: 575.98px) {
    .container{
        height: 45px;
      }
}
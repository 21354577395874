.general_button {
    min-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: white;
    font-weight: 600;
    background-color: var(--cerulean);
    display: flex; 
    justify-content: center; 
    align-items: center;
    border-radius: 12px; 
    border: 1px solid var(--ocean);
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.disabled_general_button {
    min-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: white;
    font-weight: 600;
    background-color: gainsboro;
    display: flex; 
    justify-content: center; 
    align-items: center;
    cursor: pointer;
    border-radius: 12px; 
    border: 1px solid gainsboro;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.general_button:hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.general_button .loading_icon {
    width: 15px;
    height: 15px; 
    margin-right: 5px;
}
.general_button .front_icon {
    width: 20px; 
    margin-right: 5px;
}
.disabled_general_button .front_icon {
    width: 20px; 
    margin-right: 5px;
}
.general_button .back_icon {
    width: 12px;
    height: 12px; 
    margin-left: 5px;
}
.disabled_general_button .back_icon {
    width: 12px;
    height: 12px; 
    margin-left: 5px;
}
.general_button .text {
    margin: 0;
}
.disabled_general_button .text {
    margin: 0;
}

@media (max-width: 991.98px) {  
    .general_button .front_icon {
        width: 24px; 
        margin-right: 5px;
    }
    .disabled_general_button .front_icon {
        width: 24px; 
        margin-right: 5px;
    }
}

@media (max-width: 575.98px) {  
    .general_button {
        border-radius: 6px; 
    }
    .disabled_general_button {
        border-radius: 6px; 
    }
    .general_button .front_icon {
        width: 20px; 
    }
    .disabled_general_button .front_icon {
        width: 20px; 
    }
}

@media screen and (max-width: 425.98px) {
    .general_button {
        min-height: 40px;
    }
    .disabled_general_button {
        min-height: 40px;
    }
}